<script setup lang="ts">
import type { SimpleTableColumn, SimpleTableData } from './types'
import type { SimpleTableVariant } from './style'
import {
  createHeaderSlotProps,
  createItemSlotProps,
  createFooterSlotProps,
  getTemplate,
} from './utils'

const props = defineProps<{
  columns: SimpleTableColumn[]
  data: SimpleTableData
  variant: SimpleTableVariant
  footer: boolean
}>()

const emit = defineEmits<{
  'click:row': [value: any]
}>()

const data = computed(() =>
  Array.isArray(props.data) ? props.data : [props.data],
)
</script>

<template>
  <table :class="variant.table()">
    <!-- Header -->
    <thead :class="variant.thead()">
      <tr :class="variant.theadTr()">
        <th
          v-for="(column, idx) in columns"
          :key="column.id"
          :class="variant.theadTh()"
        >
          <slot
            :name="`header-${column.id}`"
            v-bind="createHeaderSlotProps(column, idx)"
          >
            <slot name="header" v-bind="createHeaderSlotProps(column, idx)">
              <span>{{ column.header }}</span>
            </slot>
          </slot>
        </th>
      </tr>
    </thead>
    <!-- Body -->
    <tbody :class="variant.tbody()">
      <tr
        v-for="(item, idx) in data"
        :key="idx"
        :class="variant.tbodyTr()"
        @click="emit('click:row', item)"
      >
        <td
          v-for="column in columns"
          :key="column.id"
          :class="variant.tbodyTd()"
        >
          <slot
            :name="`item-${column.id}`"
            v-bind="createItemSlotProps(column, item, idx)"
          >
            <slot name="item" v-bind="createItemSlotProps(column, item, idx)">
              <span>{{ getTemplate(column, item) }}</span>
            </slot>
          </slot>
        </td>
      </tr>
    </tbody>
    <!-- Footer -->
    <tfoot v-if="footer" :class="variant.tfoot()">
      <tr :class="variant.tfootTr()">
        <th
          v-for="(column, idx) in columns"
          :key="column.id"
          :class="variant.tfootTh()"
        >
          <slot
            :name="`footer-${column.id}`"
            v-bind="createFooterSlotProps(column, idx)"
          >
            <slot name="footer" v-bind="createFooterSlotProps(column, idx)">
              <span>{{ column.footer }}</span>
            </slot>
          </slot>
        </th>
      </tr>
    </tfoot>
  </table>
</template>
